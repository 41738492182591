.school-layout {
    pointer-events: all;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--app-container-inset, .8rem)
}

.school-carousel {
    display: flex;
    gap: .8rem;
}

.school-list-view {
    --school-list-view-gap: 2rem;

    display: grid;
    grid-template-rows: auto 1fr;
    gap: var(--school-list-view-gap);
    padding: var(--content-inset)
}

.school-list-view-header {
    position: relative;

    display: flex;
    gap: 2rem;
    align-items: center;

    padding-bottom: var(--content-inset);

    .da-search {
        min-width: 24rem;
    }
}

.school-list-view-header:before {
    content: "";
    position: absolute;
    z-index: 100;

    height: 1px;
    left: calc(var(--content-inset) * -1);
    right: calc(var(--content-inset) * -1);
    bottom: 0;

    background-color: var(--divider-color);
}

.school-list-view-item {
    cursor: pointer;

    border-radius: .4rem;

    padding-inline: 1.2rem;
    padding-block: 1rem;

    display: flex;
    gap: .8rem;

    span {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-400);
    }
}

.school-list-view-item[aria-selected=true] {
    background-color: var(--item-selected-color);
}

.school-list-view-item:not([aria-selected=true]):hover {
    background-color: var(--item-hover-color);
}

.settings-school-urls-body {
    --button-padding-inline: 3rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.settings-school-urls-buttons {
    display: flex;
    gap: 2rem;
    justify-content: end;

    button {
        padding-inline: 3rem;
    }
}

.settings-school-urls-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .da-table-view {
        flex-grow: 1;
    }
}

.settings-school-urls-row {
    span {
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-600);
        color: var(--neutral-color-900);
        line-height: var(--font-lineheight-500);
    }

    a {
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-400);
        line-height: var(--font-lineheight-500);
    }
}