.error-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-container > div {
    display: inline;
}

.da-quicksight-console {
    border: none;
    pointer-events: all;
}