.settings-modal-card {
    padding: 0;
}

.settings-modal-container {
    display: grid;
    grid-template-columns: auto 1fr;

    height: 100%;
}

.settings-modal-left {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    background-color: var(--neutral-color-100);

    min-width: 20rem;
    max-width: 60rem;
    padding: 1.8rem .8rem;
}

.pane-group {
    display: flex;
    flex-direction: column;
    gap: .2rem;

    --horizon-padding: 2rem;
    --vertical-padding: .8rem;
}

.pane-group > span:first-child {
    color: var(--neutral-color-400);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-75);
    line-height: var(--font-lineheight-100);
    letter-spacing: .05rem;
}

.pane-item {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    padding-inline: var(--horizon-padding);
    padding-block: var(--vertical-padding);

    cursor: pointer;

    color: var(--neutral-color-600);
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-300);
    line-height: var(--font-lineheight-500);

    border-radius: .8rem;

    span {
        grid-column: 2;
        grid-row: 2;
    }

    .badge {
        position: unset;
    }
}

.pane-item[aria-selected="true"] {
    color: var(--neutral-color-800);
    background-color: var(--item-selected-color);
}

.settings-pane-spacing {
    padding: .4rem var(--horizon-padding);
}

.settings-modal-right {
    --modal-vertical-padding: 2.4rem;
    --modal-horizontal-padding: 3.2rem;

    padding-block: var(--modal-vertical-padding);
    padding-inline: var(--modal-horizontal-padding);

    display: grid;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.settings-modal-right__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 4.5rem;

    .settings-modal-right__header__buttons {
        display: flex;
        gap: 1.6rem;
        align-items: center;
    }
}

.settings-modal-right__header > span {
    color: var(--neutral-color-900);
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-800);
    line-height: var(--font-lineheight-600);
}

.settings-search {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
}

/*************************************************************/
/*********************** PERSONAL TAB ************************/
/*************************************************************/

.settings-personal-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.settings-personal-content {
    display: grid;
    gap: 1.6rem;
    grid-template-columns: repeat(2, 1fr);
    max-width: 60rem;
}

.settings-personal-content > div:last-child {
    grid-column: 1/3
}

/*************************************************************/
/************************* USERS TAB *************************/
/*************************************************************/

.settings-users-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.settings-users-list-header {
    display: flex;
    justify-content: space-between;
    gap: 1.6rem;
}

.settings-users-user-section-container {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
}

.settings-users-table-layout {
    display: grid;
    grid-template-columns: 1fr 1fr .25fr;
    align-items: center;

    padding: 1.2rem 2rem;
}

.settings-users-section {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
}

.settings-users-section-title {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.settings-users-section-header {
    span {
        color: var(--neutral-color-400);
        font-size: var(--font-size-75);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-100);
        letter-spacing: .05rem;
    }

    span:first-child {
        padding: 0 1.8rem;
    }

    span:not(:first-child) {
        padding: 0 1rem;
    }
}

.settings-users-row-container {
    background-color: var(--neutral-color-100);
    border-radius: .8rem;
}

.settings-users-row-user-info {
    display: flex;
    gap: .8rem;
    align-items: center;
}

.settings-users-row-names {
    display: flex;
    flex-direction: column;

    .title {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-500);

        span {
            font-weight: var(--font-weight-500);
        }
    }

    .email {
        color: var(--neutral-color-600);
        font-size: var(--font-size-100);
        font-weight: var(--font-weight-400);
        line-height: var(--font-lineheight-100);
    }
}

.settings-users-row-buttons {
    display: flex;
    justify-content: end;
}

.settings-users-row-role-actions {
    position: relative;
}

.settings-users-dropdown-modal {
    min-width: 30rem;
    max-width: 60rem;

    padding: .4rem
}

.settings-users-dropdown-modal-card {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    --horizon-padding: .8rem;
    padding: 1rem .4rem;
}

.settings-users-dropdown-modal-card > div:first-child {
    padding: 0 var(--horizon-padding);
}

.settings-users-dropdown-role-list {
    display: flex;
    gap: .6rem;
    flex-direction: column;
}

.settings-users-user-role-item {
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: .8rem;
    padding: .8rem var(--horizon-padding);

    span {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-500);
    }
}

.settings-users-user-role-item:hover {
    background-color: var(--item-hover-color);
}

.settings-users-user-role-item[aria-disabled="true"] {
    cursor: default;
    span {
        color: var(--disabled-color);
    }
}

.settings-users-invite-email-container {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: .6rem;

    .da-dropdown-button {
        padding: 0
    }
}

.settings-users-invite-email-header {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.settings-users-invite-emails {
    display: flex;
    gap: .6rem;
    align-items: center;
    flex-wrap: wrap;
}

.settings-users-invite-emails:empty {
    display: none;
}

.settings-users-invite-email-item-container {
    display: flex;
    gap: 1.6rem;
    align-items: center;

    --email-item-padding: .6rem;
    padding: var(--email-item-padding) var(--email-item-padding) var(--email-item-padding) 1.4rem;
    border-radius: .8rem;
    background-color: var(--primary-color-600);

    span {
        color: var(--neutral-color-0);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-500);
    }
}

.settings-users-invite-email-item-button {
    background-color: var(--neutral-color-0);
    color: var(--primary-color-600);

    border-radius: .4rem;

    svg {
        width: 20px;
        height: 20px;
    }
}

.settings-users-invite-email-item-button:hover {
    background-color: var(--item-hover-color);
}

/*************************************************************/
/************************* ROLES TAB *************************/
/*************************************************************/

.settings-role-list,
.settings-role-list-content {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
}

.settings-role-list-content {
    flex-grow: 1;
}

.settings-role-list-item-action-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings-role-list-item {
    display: flex;
    gap: 1.6rem;

    justify-content: space-between;

    padding: 1.2rem 2rem;

    background-color: var(--neutral-color-100);
    border-radius: .8rem;
}

.settings-role-list-item-info {
    display: flex;
    flex-direction: column;
    gap: .4rem;

    span:first-child {
        color: var(--neutral-color-900);
        font-weight: var(--font-weight-600);
        font-size: var(--font-size-300);
        line-height: var(--font-lineheight-500);
    }

    span:last-child {
        color: var(--neutral-color-600);
        font-weight: var(--font-weight-400);
        font-size: var(--font-size-100);
        line-height: var(--font-lineheight-100);
    }
}

.settings-role-list-item-actions {
    display: flex;
    gap: 2rem;
}

.settings-role-entry-modal {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.settings-role-entry-modal-subtitle {
    color: var(--neutral-color-900);
    font-weight: var(--font-weight-600);
    font-size: var(--font-size-200);
    line-height: var(--font-lineheight-100);
}

.settings-role-entry-modal-permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    height: 2.4rem;
    text-align: end;
}

.settings-role-entry-modal-permission > span {
    color: var(--neutral-color-500);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-200);
    line-height: var(--font-lineheight-350);
}

/*************************************************************/
/********************** INTEGRATION TAB **********************/
/*************************************************************/

.settings-integrations-tab-body {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;

    .da-search {
        flex-grow: unset;
    }
}

.settings-integration-group {
    display: flex;
    gap: 3rem;
    flex-direction: column;
}

.settings-integration-group-name {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.settings-integration-group-items {
    display: flex;
    gap: 1.6rem;
    flex-direction: column;
}

.settings-integration-group-item {
    padding: 1.2rem 2rem;
    border-radius: .8rem;
    background-color: var(--neutral-color-100);

    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.settings-integration-group-item-info {
    display: flex;
    gap: .8rem;
    flex-direction: column;

    justify-content: center;
}

.settings-integration-group-item-name {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.settings-integration-group-item-description,
.settings-integration-group-item-description-read-more {
    color: var(--neutral-color-600);
    font-size: var(--font-size-200);
    font-weight: var(--font-weight-400);
    line-height: var(--font-lineheight-100);
}

.settings-integration-group-item-description:empty {
    display: none;
}

.settings-integration-group-item-description-read-more {
    font-weight: var(--font-weight-600);
}

.settings-integration-action-connected-group {
    display: flex;
    gap: .8rem;
}

.settings-integration-action-button-primary,
.settings-integration-action-button-danger {
    padding: .8rem 2rem;
}

.settings-integration-action-button-primary {
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.settings-integration-action-button-primary[disabled] {
    background-color: var(--neutral-color-250);
    color: var(--neutral-color-0);
}

.settings-integration-action-button-danger {
    background-color: transparent;

    color: var(--red-color-500);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.settings-integration-action-button-danger:hover {
    background-color: var(--red-color-500);
    color: var(--neutral-color-0);
}

.settings-integration-connect-modal-card {
    min-height: 25rem;
    min-width: 60vw;
    max-width: 80vw;

    .da-modal-entry-body {
        flex-grow: 1;
    }
}

.settings-integration-connect-modal-entry-container {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.settings-integration-connect-modal-entry-container > div {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.settings-integration-connect-modal-entry-array-container {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.settings-integration-connect-modal-entry-array-container > div {
    display: flex;
    gap: 1.2rem;
    align-items: center;
}