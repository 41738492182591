.split-layout {
    pointer-events: none;

    display: flex;
    transition: 300ms;
}

.split-layout-pane {
    --toggle-button-width: 4rem;
    --toggle-button-height: 9rem;
    --toggle-button-icon-size: 5rem;

    pointer-events: all;

    display: flex;
    flex-direction: column;
    position: relative;

    &.left {
        .da-card {
            display: flex;
            flex-direction: column;

            /* get around the mapbox logo */
            padding-bottom: 5rem;

            &:has(.da-empty-view) {
                gap: 4rem;
            }

            .da-empty-view {
                gap: 1.6rem;
            }
        }

        .split-layout-pane-button {
            right: calc(var(--toggle-button-width) * -1);
        }
    }

    &.right {
        .da-card {
            display: grid;
        }

        &.two-column > .da-card {
            grid-template-rows: 1fr;
        }

        &.three-column > .da-card {
            grid-template-rows: auto 1fr;
        }

        .split-layout-pane-button {
            left: calc(var(--toggle-button-width) * -1);
        }
    }

    .da-card {
        width: 45rem;
    }

    .da-card,
    .map-listview-content {
        flex-grow: 1;
    }
}

.split-layout-pane > .da-card[aria-hidden=true] {
    display: none;
}

.split-layout-center {
    pointer-events: none;

    flex-grow: 1;
    padding: 1.4rem;

    & > div:first-child {
        pointer-events: none;

        position: relative;
        height: 100%;
    }
}

.split-layout-pane-button {
    pointer-events: none;

    position: absolute;
    top: 0;
    bottom: 0;

    display: flex;
    align-items: center;

    button {
        pointer-events: all;
        width: var(--toggle-button-width);
        height: var(--toggle-button-height);
    }

    svg {
        transform: scaleY(180%);
        width: var(--toggle-button-icon-size);
        height: var(--toggle-button-icon-size);
        overflow: unset;
        overflow-clip-margin: unset;
    }
}