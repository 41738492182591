.settings-admin-integration-list {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.settings-admin-integration-item-header-expanded {
    border-radius: .8rem .8rem 0 0;
}

.settings-admin-integration-item-header-closed {
    border-radius: .8rem;
}

.settings-admin-integration-header,
.settings-admin-integration-item-header {
    cursor: pointer;

    display: grid;
    grid-template-columns: 4rem 1fr .6fr 12rem 16rem 12rem;
    gap: 1.2rem;

    align-items: center;
}

.settings-admin-integration-header > span {
    color: var(--neutral-color-400);
    font-size: var(--font-size-75);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-100);
    letter-spacing: .05rem;
}

.settings-admin-integration-item-header {
    height: 6rem;
    background-color: var(--neutral-color-100);
}

.settings-admin-integration-item-header-icon {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    aspect-ratio: 1 / 1;
    height: 3.2rem;

    svg {
        color: var(--neutral-color-500);
        width: 2rem;
        height: 2rem;
    }
}

.settings-admin-integration-item-header-icon[aria-expanded=true] {
    transform: rotate(-90deg);
}

.settings-admin-integration-item-header-title {
    display: flex;
    flex-direction: column;
}

.settings-admin-integration-item-header-title > span:first-child {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.settings-admin-integration-item-header-title > span:not(:only-child):last-child {
    color: var(--neutral-color-600);
    font-size: var(--font-size-100);
    font-weight: var(--font-weight-400);
    line-height: var(--font-lineheight-100);

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 12rem;
}

.settings-admin-integration-item-header-tag {
    .da-select-container {
        display: inline-block;
    }

    .da-select__control {
        cursor: pointer;

        background-color: var(--neutral-color-150);
        border-radius: .8rem;

        min-height: unset;
    }
}

.settings-admin-integration-item-header-tag > div {
    display: inline-block;
}

.settings-admin-integration-item-header-is-active {
    width: 4rem;
}

.settings-admin-integration-item-header-is-sys-admin {
}

.settings-admin-integration-schools:empty {
    display: none;
}

.settings-admin-integration-schools:not(:empty) {
    .da-empty-view {
        background-color: var(--neutral-color-0);
        border-radius: 0 0 .8rem .8rem;
        border: 1px solid var(--border-color);
    }
}

.settings-admin-integration-school-header,
.settings-admin-integration-school {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.2rem;
    align-items: center;
    justify-content: center;

    padding-inline: 2rem;
}

.settings-admin-integration-school-header,
.settings-admin-pending-school-header {
    padding-block: 1.2rem;

    border: 1px solid var(--border-color);
}

.settings-admin-integration-school,
.settings-admin-pending-school {
    padding-block: .8rem;

    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
}

.settings-admin-integration-school:hover,
.settings-admin-pending-school:hover {
    background-color: var(--item-hover-color);
}

.settings-admin-integration-school:last-child,
.settings-admin-pending-school:last-child {
    border-radius: 0 0 .8rem .8rem;
}

.settings-admin-integration-school__school,
.settings-admin-pending-school__school {
    display: flex;
    gap: .8rem;
    align-items: center;
}

.settings-admin-integration-school__school > span,
.settings-admin-pending-school__school > span {
    color: var(--neutral-color-900);
    font-size: var(--font-size-200);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-100);
}

.settings-admin-integration-checked {
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-admin-integration-checked__checkbox {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 1 / 1;
    height: 2rem;

    border-radius: 50%;
    border: 1px solid var(--border-color);

    svg {
        color: var(--neutral-color-0)
    }
}

.settings-admin-integration-checked__checkbox[aria-readonly=true] {
    cursor: default;
}

.settings-admin-integration-checked__checkbox[aria-checked=true] {
    background-color: var(--green-color-500);
}

.settings-admin-integration-checked__checkbox[aria-readonly=false][aria-checked=true]:hover {
    background-color: var(--green-color-600);
}

.single-color[aria-checked=false] {
    background-color: transparent;
}

.single-color[aria-readonly=false][aria-checked=false]:hover {
    background-color: var(--neutral-color-200);
}

.double-color[aria-checked=false] {
    background-color: var(--red-color-500);
}

.double-color[aria-readonly=false][aria-checked=false]:hover {
    background-color: var(--red-color-600);
}

.settings-admin-pending {
}

.settings-admin-pending-header {
    border-radius: .8rem .8rem 0 0;
    background-color: var(--neutral-color-100);

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-inline: 2rem;
    height: 6rem;
}

.settings-admin-pending-header__buttons {
    display: flex;
    gap: 1.2rem;
    align-items: center;
}

.settings-admin-pending-school-header,
.settings-admin-pending-school {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;

    align-items: center;

    border-left: 1px solid var(--border-color);
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    padding-inline: 2rem;
}

.settings-admin-timestamp {
    color: var(--neutral-color-600);
    font-weight: var(--font-weight-400);
    font-size: var(--font-size-100);
    line-height: var(--font-lineheight-100);
}

.settings-ignore-ap {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 2rem;
}

.settings-ignore-ap-buttons {
    display: flex;
    gap: 1.6rem;
    justify-content: end;
}

.settings-ignore-ap-row-buttons {
    display: flex;
    gap: .8rem;
    justify-content: end;
}

.settings-ignore-ap-modal-card {
    width: 60vw;
    min-width: 80rem;

    display: grid;
    grid-template-rows: auto 1fr auto;
}

.settings-ignore-ap-modal-body {
    display: grid;
    grid-template-rows: auto 40vh;
    gap: 1.6rem;
}

.settings-ignore-ap-modal-inputs {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.settings-ignore-ap-modal-aps-matches {
    display: grid;
    grid-template-rows: auto 40vh;
}

.settings-ignore-ap-modal-aps-matches-content {
    .scroll-view {
        border-radius: .8rem;
        border: 1px solid var(--border-color);
    }

    .scrollable {
        padding: 2rem;
    }
}

.settings-ignore-ap-modal-aps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;

    span {
        text-align: start;
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-400);
    }
}

.settings-user-management-user {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    background-color: var(--neutral-color-100);
    border-radius: .8rem;

    padding-block: 1.2rem;
    padding-inline: 2rem;

    .settings-user-management-user-header {
        display: flex;
        gap: 1.8rem;
        justify-content: space-between;
        align-items: center;

        & > div {
            display: flex;
            gap: 1.2rem;
            align-items: center;

            & > span:first-child {
                color: var(--neutral-color-900);
                font-size: var(--font-size-300);
                font-weight: var(--font-weight-600);
                line-height: var(--font-lineheight-500);
            }

            & > span:last-child {
                color: var(--neutral-color-700);
                font-size: var(--font-size-300);
                font-weight: var(--font-weight-500);
                line-height: var(--font-lineheight-500);
            }
        }
    }
}

.settings-user-management-new-user-modal {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .da-modal-entry-input-content > span {
        flex-grow: 0;
    }
}