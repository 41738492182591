#map-content {
    height: 100vh;
    width: 100vw;
}

.map-container {
    position: relative;
    width: 100%;
    height: 100%;
}

.map-overlay {
    display: grid;
    grid-template-columns: auto 1fr;
    /*gap: 2rem*/
    pointer-events: none;
}

.map-settings {
    pointer-events: all;
    width: 300px;
}

.map-listview {
    padding-inline: 0;
}

.map-listview-header {

}

.map-listview-content {
    pointer-events: all;

    .scroll-view-container {
        .scrollable {
            padding-block: var(--da-card-inset) 0;
        }
    }
}


.map-listview-header,
.map-listview-content > .scroll-view-container {
    padding-inline: var(--da-card-inset);
}

.map-listview-collapsable {
    display: flex;
    flex-direction: column;
}

.map-listview-collapsable-layout {
    --collapsable-caret-size: 2.4rem;

    display: grid;
    grid-template-columns: var(--collapsable-caret-size) 1fr;

    .map-listview-collapsable-layout-caret {
        display: flex;
        flex-direction: column;
        align-items: start;
        cursor: pointer;

        & > div:first-child {
            /* magic number but it's the size of the layout to the right of it.  ideally, i would refactor */
            /* the layout but i don't have time for that atm */
            height: 4.4rem;
            display: flex;
            align-items: center;
        }

        svg {
            width: var(--collapsable-caret-size);
            height: var(--collapsable-caret-size);

            transform: rotate(-90deg);
        }

        &[aria-expanded="true"] {
            svg {
                transform: rotate(0deg);
            }
        }
    }
}

.map-listview-collapsable-layout-caret[aria-expanded="true"] {
}

.map-listview-collapsable-header {
    --map-header-background-color: transparent;
    --icon-size: 2.8rem;

    display: grid;
    grid-template-areas: "content";

    padding-inline: 1.2rem;
    padding-block: .8rem;

    border-radius: .8rem;
    background-color: var(--map-header-background-color);

    &.selectable {
        cursor: pointer;
    }

    .map-listview-collapsable-header-content {
        grid-area: content;

        display: grid;
        grid-template-rows: auto auto;
        column-gap: 1rem;
        align-items: center;

        &.two-column {
            grid-template-columns: 0 1fr auto;
        }

        &.three-column {
            grid-template-columns: var(--icon-size) 1fr auto;
        }

        svg {
            width: var(--icon-size);
            height: var(--icon-size);

            grid-column: 1;
            grid-row: 1;
        }

        .map-listview-collapsable-header-content-buttons {
            display: flex;
            align-items: center;
        }

        .map-listview-collapsable-header-content-primary {
            color: var(--neutral-color-900);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-400);

            grid-column: 2;
            grid-row: 1;
        }

        .map-listview-collapsable-header-content-secondary {
            color: var(--neutral-color-400);
            font-size: var(--font-size-100);
            font-weight: var(--font-weight-400);
            line-height: var(--font-lineheight-100);

            flex-grow: 0;
            overflow: hidden;
            max-height: calc(var(--font-lineheight-100) * 2);

            grid-column: 2;
            grid-row: 2;
        }

        .map-listview-collapsable-header-content-buttons {
            grid-column: 3;
            grid-row: 1;
        }

        .map-listview-collapsable-header-click {
            grid-column: 2 / 3;
            grid-row: 1 / 3;

            height: 100%;
        }

        &:has(> .map-listview-collapsable-header-content-secondary:empty) {
            grid-template-rows: unset;

            span:last-child {
                display: none;
            }

            .map-listview-collapsable-header-click {
                grid-row: 1 / 2;
            }
        }
    }

    &[aria-selected="true"] {
        --map-header-background-color: var(--item-selected-color);
    }
}

/*.map-listview-collapsable-header-content-icons-indicator {*/
/*    --indicator-size: 1rem;*/
/*    --indicator-color: var(--green-color-600);*/

/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/

/*    & > div {*/
/*        width: var(--indicator-size);*/
/*        height: var(--indicator-size);*/
/*        border-radius: 50%;*/
/*        background-color: var(--indicator-color);*/
/*    }*/

/*    & > div.warning {*/
/*        --indicator-color: var(--orange-color-600);*/
/*    }*/

/*    & > div.danger {*/
/*        --indicator-color: var(--red-color-600);*/
/*    }*/
/*}*/

.map-listview-collapsable-content {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    grid-column: 2 / 3;
}

.map-buttons {
    pointer-events: none;
    padding: 1.4rem;
}

.map-buttons > div:first-child {
    pointer-events: none;

    position: relative;
    height: 100%;
}

.map-buttons-options {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    gap: .8rem;
}

.map-option {
    cursor: pointer;
    pointer-events: all;

    display: flex;
    gap: .8rem;
    align-items: center;
    justify-content: center;

    border-radius: .8rem;

    padding: .8rem;

    span {
        color: var(--neutral-color-800);
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-200);
    }
}

.map-option-geocoder-container {
    pointer-events: all;
    width: 30rem;
}

.map-buttons-filters {
    pointer-events: all;
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    gap: 1.6rem;
}

.map-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .2rem;

    cursor: pointer;
}

.map-filter > div {
    position: relative;
}

.map-filter span {
    color: var(--neutral-color-700);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-100);
    line-height: var(--font-lineheight-100);
}

.map-filter[aria-selected="true"] > div::before {
    position: absolute;
    content: "";
    inset: 0;

    border: solid 2px var(--primary-color-600);
    border-radius: .8rem;
}

.map-filter[aria-selected="true"] span {
    font-weight: var(--font-weight-600);
}

.map-buttons-controls {
    pointer-events: all;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 200px;
    width: 75px;
}

.collapsable-title {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.collapsable-title__header {
    display: flex;
    gap: 1.6rem;

    svg {
        font-size: var(--font-size-800);
        color: var(--neutral-color-500);
        width: 2.4rem;
        height: 2.4rem;
    }

    svg[aria-expanded="true"] {
        transform: rotate(-90deg);
    }
}

.collapsable-title__header__text {
    span:first-child {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-400);
    }

    span:last-child {
        color: var(--neutral-color-400);
        font-size: var(--font-size-100);
        font-weight: var(--font-weight-400);
        line-height: var(--font-lineheight-100);
    }

    span:last-child:empty {
        display: none;
    }
}

.map-overlay-center-layout {
    display: grid;
    grid-template-rows: 1fr auto;
    gap: 1.2rem;

    & > div:first-child {
        pointer-events: none;
        position: relative;
    }
}

.map-overlay-control {
    --control-icon-size: 1.5rem;

    --control-button-size: 3.6rem;
    --control-button-radius: .8rem;

    pointer-events: all;

    & > * {
        overflow: hidden;
    }

    & > *:first-child {
        border-top-left-radius: var(--control-button-radius);
        border-top-right-radius: var(--control-button-radius);
    }

    & > *:last-child {
        border-bottom-left-radius: var(--control-button-radius);
        border-bottom-right-radius: var(--control-button-radius);
    }

    &:empty {
        display: none;
    }
}

.map-overlay-draw-buttons {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    position: absolute;
    top: 0;
    right: 0;
}

.map-overlay-control-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
}

.map-overlay-control-icon {
    position: relative;

    background-color: var(--neutral-color-0);
    width: var(--control-button-size);
    height: var(--control-button-size);

    box-shadow: 0 .2rem 1.6rem hsl(from var(--shadow-color) H S L / .1);

    & > button {
        width: var(--control-button-size);
        height: var(--control-button-size);

        background-color: transparent;
    }

    &.secondary[aria-selected="true"] {
        button {
            background-color: var(--primary-color-600a);
        }

        svg {
            color: var(--selected-color);
        }
    }

    &.primary svg {
        color: var(--primary-color-500);
    }

    &.secondary svg {
        color: var(--neutral-color-600);
    }

    &.danger svg {
        color: var(--red-color-500);
    }
}

.map-overlay-edit-banner {
    pointer-events: all;

    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    align-items: center;

    background-color: var(--primary-color-600);
    border-radius: 1.2rem;

    padding-block: .8rem;
    padding-inline: 1.6rem;

    & > span {
        color: var(--neutral-color-0);
        font-size: var(--font-size-400);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-500);
    }

    & > div {
        display: flex;
        gap: 1.2rem;

        & > button {
            width: 9.1rem;
            height: 3.2rem;

            font-size: var(--font-size-300);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-500);
        }

        & > button.da-button-primary {
            background-color: var(--neutral-color-0);
            color: var(--primary-color-600);
        }

        & > button.da-button-primary:disabled {
            color: var(--disabled-color);
        }

        & > button.da-button-primary:not(:disabled):hover {
            background-color: var(--neutral-color-150);
        }

        & > button.da-button-secondary {
            background-color: var(--primary-color-200);
            color: var(--neutral-color-0);
        }

        & > button.da-button-secondary:not(:disabled):hover {
            background-color: var(--primary-color-500);
        }
    }
}

.map-overlay-polygon-info {
    pointer-events: all;

    position: absolute;
    right: 0;
    bottom: 8rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}