.school-page {
    display: flex;
    flex-direction: column;
    gap: 2.2rem;

    pointer-events: all;
}

.school-page-header {

}

.school-page > span,
.school-page-header-name {
    color: var(--neutral-color-900);
    font-size: var(--font-size-950);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-800);
}

.school-page-header-links {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    button {
        --icon-size: 1.6rem;
    }

    span {
        color: var(--neutral-color-700);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-400);
    }
}

.school-tab {
    display: flex;
    flex-direction: column;
}

.school-tab-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex-grow: 1;
}

.school-tab-body > div:first-child {
    max-width: 120rem;
}

.school-group {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.school-group__slider {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
    justify-content: space-between;
}

.school-group__label {
    padding-block: .8rem;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.school-general-tab,
.school-admin-tab {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    flex-grow: 1;
}

.school-general-layout,
.school-admin-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 2rem 4rem;
}

.school-add-button {
    border-radius: 50%;
}

.school-semester-tab {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.school-semester-search {
    display: flex;
    gap: 1.6rem;
}

.school-semester-tab-groups {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.school-semester-layout {
    --school-semester-layout-padding-inline: 1.2rem;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--school-semester-gap, 2rem);

    align-items: center;
}

.school-semester-layout > div:last-child {
    justify-content: end;
}

.school-semester-header {
    color: var(--neutral-color-400);
    font-size: var(--font-size-100);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-100);
    letter-spacing: .05rem;

    padding-inline: var(--school-semester-layout-padding-inline);
}

.school-semester {
    --school-semester-gap: 2rem;

    padding-inline: var(--school-semester-layout-padding-inline);
    padding-block: .8rem;

    border-radius: .8rem;

    background-color: var(--neutral-color-100);
}

.school-semester > span {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.school-semester > div {
    display: flex;
    gap: var(--school-semester-gap, 2rem);
}

.school-semesters {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.school-semester-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.school-admin-ratio-input {
    display: grid;
    grid-template-columns: 1fr auto 1fr auto;
    gap: 1.6rem;
    align-items: center;

    flex-grow: 1;
}

.school-map-layout {
    position: relative;
    flex-grow: 1;

    mapbox-geocoder {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 100%;
        max-width: 40%;
    }
}