.da-navbar {
    pointer-events: all;
    display: flex;
    flex-direction: column;
    padding: unset;

    overflow: unset;
}

.da-navbar-layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 40px;
    padding: 1.6rem;

    a {
        text-decoration: none;
    }
}

.da-navbar-school {
    font-size: var(--font-size-400);
}

.da-navbar-layout-content,
.da-navbar-layout-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.da-navbar-layout-content {
    gap: 5.6em;
}

.da-navbar-layout-content > div {
    display: flex;
    gap: 2.4em;
}

.da-navbar-layout-actions {
    gap: 2em;
}

.da-nav-item {
    display: flex;
    gap: .8em;
    align-items: center;

    color: var(--font-color-700);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.da-nav-item[aria-selected="true"] a {
    color: var(--selected-color);
}

.da-nav-item[aria-selected="true"] svg {
    color: var(--selected-color);
}

.da-nav-item[aria-selected="false"] a {
    color: var(--neutral-color-700);
}

.da-nav-item[aria-selected="false"] svg {
    color: var(--neutral-color-700);
}

.nav-username {
    .name-icon {
        --icon-size: 2.8rem;
        cursor: pointer;
    }

    .name-icon:hover {
        background-color: var(--icon-hover);
    }
}

.nav-dropdown {
    position: absolute;
    top: 7rem;
    right: 2rem;
    width: 24rem;

    display: flex;
    justify-content: center;
    align-items: center;

    .da-card {
        padding: 0;
        width: 100%;
    }
}

.nav-dropdown-content {
    display: flex;
    flex-direction: column;
}

.nav-dropdown-group {
    display: flex;
    flex-direction: column;

    padding: .4rem;
}

.da-nav-dropdown-item {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .8rem;

    padding: 1rem 2.2rem;

    font-weight: var(--font-weight-500);
    font-size: var(--font-size-300);
    line-height: var(--font-lineheight-500);

    cursor: pointer;

    color: var(--neutral-color-800);

    svg {
        width: 2.4rem;
        aspect-ratio: 1;
        color: var(--neutral-color-300);
    }
}

.da-nav-dropdown-item:hover {
    background-color: var(--item-hover-color);
    border-radius: 1rem;
}

.da-nav-dropdown-item.danger-action:hover {
    background-color: var(--danger-action-hover-color);
}

.da-nav-dropdown-item.danger-action {
    color: var(--red-color-500);

    svg {
        color: var(--red-color-500)
    }
}