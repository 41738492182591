.alerts {
    pointer-events: all;

    display: flex;
    flex-direction: column;

    padding: unset;
}

.alerts-header {
    display: flex;
    align-items: center;
    gap: .8rem;

    padding: 1.6rem;
    height: 3.6rem;
}

.alerts-tableview {
    --alerts-tableview-inset-inline: 1.6rem;

    flex-grow: 1;
    gap: unset;
}

.alerts-tableview-header {
    background-color: var(--neutral-color-100);
    padding-block: 1rem;
    padding-inline: var(--alerts-tableview-inset-inline);
    border-bottom: solid 1px var(--border-color);
}

.alerts-tableview-content-scrollable {
    gap: unset;
}

.alerts-tableview-row {
    --row-content-color: var(--neutral-color-0);

    padding-block: 1.6rem;
    padding-inline: var(--alerts-tableview-inset-inline);
    background-color: var(--row-content-color);
    border-bottom: solid 1px var(--border-color);

    min-height: 4rem;
}

.alerts-tableview-row-paused {
    --row-content-color: hsl(from var(--neutral-color-100) H S L / .5);
}

.alerts-tableview-row-paused-icon {
    display: flex;
    gap: .8rem;
    align-items: center;

    padding-block: .2rem;
    padding-inline: .8rem;
    background-color: var(--neutral-color-150);
    border-radius: .6rem;

    color: var(--neutral-color-600);

    span {
        font-size: var(--font-size-200);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-325);
    }
}

.alerts-tableview-row-text {
    color: var(--font-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.alerts-tableview-row-text-paused {
    color: var(--font-color-700);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.alerts-tableview-row-name {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.alerts-tableview-row-type {

}

.alerts-tableview-row-priority {

}

.alerts-tableview-row-schedule {
    display: flex;
    flex-direction: column;
}

.alerts-tableview-row-schedule-time {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.alerts-tableview-row-schedule-next {
    color: var(--neutral-color-700);
    font-size: var(--font-size-200);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-100);
}

.alerts-tableview-row-schedule-time:empty {
    display: none;
}

.alert-detail {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 2rem;
}

.alert-detail-header {
    display: flex;
    flex-direction: column;
    gap: .4rem;

    input {
        border: none;

        color: var(--neutral-color-900);
        font-size: var(--font-size-950);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-800);
    }

    input::placeholder {
        color: var(--neutral-color-500);
    }

    input:focus-visible {
        outline: solid 1px var(--border-color);
    }
}

.alert-detail-header-type {
    font-size: var(--font-size-400);
}

.alert-detail-body {
    display: flex;
    height: 100%;

    position: relative;

    .da-tab {
        position: absolute;
        inset: 0;
    }
}

.alert-detail-body-content {
    width: 100%;
    position: relative;
}

.alert-detail-body-query-container {
    position: relative;
    height: 100%;
}

.alert-detail-body-query {
    position: absolute;
    inset: 0;

    display: grid;
    grid-template-rows: auto 1fr;
    gap: 3rem;
    height: 100%;

    .da-table-view {
        overflow: scroll;
    }
}

.alert-detail-body-query-body {
    min-height: 30rem;
    resize: none;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.alert-detail-body-query-header {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.alert-detail-body-query-header-buttons {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    justify-content: space-between;

    .da-modal-entry-select {
        max-width: 12rem;
        text-align: end;

        .da-select__option {
            text-align: inherit;
        }
    }
}

.alert-detail-body-query-header-buttons-required-columns {
    flex-grow: 1;

    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: .8rem;

    .column-label,
    .column-value {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        line-height: var(--font-lineheight-500);
    }

    .column-label {
        font-weight: var(--font-weight-500);
    }

    .column-value {
        display: flex;
        gap: .6rem;

        font-weight: var(--font-weight-800);

        &.danger {
            color: var(--red-color-500);
        }
    }
}

.alert-detail-body-query-preview {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.alert-detail-body-query-preview-header {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.alert-detail-body-query-preview-table {
    flex-grow: 1;

    .da-table-view-header {
        th {
            padding-block: 1rem;
            background-color: var(--neutral-color-150);

            color: var(--neutral-color-600);
            font-size: var(--font-size-75);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-100);
            letter-spacing: .035rem;
        }
    }

    .da-table-view-content {
        position: static;
        border-spacing: 0 0;
    }

    .da-table-view-content-scrollable {
        position: static;
        gap: .8rem;
    }

    .da-table-view-row {
        td {
            background-color: var(--neutral-color-0);
            border-bottom: solid 1px var(--border-color);
        }
    }
}

.alert-detail-buttons {
    justify-content: end;
    display: flex;
    gap: 1.6rem;

}

.alert-detail-body-general {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 1.2rem;
    column-gap: 2rem;

    max-width: 70rem;

    .da-modal-entry-schedule,
    .alert-detail-message-input,
    .alert-detail-notifications {
        grid-column: 1 / 3;
    }

    .alert-detail-message-input {
        textarea {
            resize: none;
        }
    }
}

.da-modal-entry-input-schedule {
    cursor: pointer;

    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
    align-items: center;

    border: solid 1px var(--border-color);
    border-radius: .8rem;

    padding-inline: .8rem;

    span {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-500);
    }

    svg {
        color: var(--neutral-color-400);
    }
}

.da-modal-entry-input-schedule:hover {
    border: solid 1px var(--button-hover-color);
}

.alert-detail-notifications {
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.alert-detail-notifications-title {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.alert-detail-notifications-row {
    display: flex;

}

.alert-detail-notifications-row-header {
    display: flex;

    gap: .8rem;
    justify-content: space-between;

    padding-block: 1rem;
    padding-inline: 1.2rem;

    background-color: var(--neutral-color-100);
    border-radius: .8rem;

    & > .alert-detail-notifications-row-header-container {
        display: flex;
        align-items: center;
        gap: .8rem;

        & > span {
            color: var(--neutral-color-900);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-600);
            line-height: var(--font-lineheight-500);
        }

        & > svg {
            aspect-ratio: 1/1;
            width: 2.4rem;
        }
    }

    & > .da-modal-entry-slider {
        margin-left: auto;
    }
}

.alert-detail-notifications-row-header-container[aria-disabled="true"] {
    & > svg {
        opacity: 30%;
    }

    & > span {
        color: var(--disabled-color);
    }
}

.alert-detail-schedule-modal {
    overflow: unset;
}

.alert-detail-schedule-modal-body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.alert-detail-schedule-modal-dates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;

    .da-modal-entry-date {
        flex-grow: 1;
    }
}

.da-modal-entry-day-picker {
}

.da-modal-entry-input-day-picker {
    display: flex;
    gap: .4rem;
    justify-content: space-evenly;
}

.da-modal-entry-input-day-picker > span {
    --day-picker-border-color: var(--border-color);

    border: solid 1px var(--day-picker-border-color);
    border-radius: .8rem;

    padding: .8rem;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
    text-align: center;
}

.da-modal-entry-input-day-picker > span[aria-selected=false]:hover {
    background-color: var(--neutral-color-100);
}

.da-modal-entry-input-day-picker > span[aria-selected=true] {
    --day-picker-border-color: var(--primary-color-500);
    background-color: var(--day-picker-border-color);
    color: var(--neutral-color-0);
}

.da-modal-entry-input-day-picker > span[aria-selected=true]:hover {
    --day-picker-border-color: var(--primary-color-600);
    background-color: var(--day-picker-border-color);
    color: var(--neutral-color-0);
}

.da-modal-entry-input-time-picker {
    display: grid;
    grid-template-columns: auto 9rem;
    gap: 1.6rem;

    input {
        border: none;
        padding: unset;
        padding-block: .5rem;

        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-500);
    }
}

.da-modal-entry-input-time-picker > .da-modal-entry-select {

}