.html,
.body,
#root,
.App {
    height: 100vh;
    width: 100vw;
}

* {
    padding: 0;
    margin: 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    padding: 0;
    margin: 0;
}

ol, dl, ul {
    margin: 0;
}

:root {
    --bs-body-font-size: 10px;

    --content-inset: 2rem;

    --font-size-75: 1rem;
    --font-size-100: 1.1rem;
    --font-size-200: 1.2rem;
    --font-size-300: 1.3rem;
    --font-size-400: 1.4rem;
    --font-size-500: 1.5rem;
    --font-size-600: 1.6rem;
    --font-size-700: 1.7rem;
    --font-size-800: 1.8rem;
    --font-size-900: 1.9rem;
    --font-size-950: 2.0rem;
    --font-size-975: 2.4rem;

    --font-lineheight-100: 1.6rem;
    --font-lineheight-200: 1.6rem;
    --font-lineheight-300: 1.6rem;
    --font-lineheight-325: 2rem;
    --font-lineheight-350: 2.1rem;
    --font-lineheight-400: 2.275rem;
    --font-lineheight-500: 2.4rem;
    --font-lineheight-600: 2.6rem;
    --font-lineheight-700: 2.8rem;
    --font-lineheight-800: 3.6rem;

    --font-weight-100: 100;
    --font-weight-200: 200;
    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;

    --font-color-400: rgb(158, 170, 182);
    --font-color-600: rgb(108, 124, 139);
    --font-color-700: rgb(86, 100, 115);
    --font-color-800: rgb(57, 69, 81);
    --font-color-900: rgb(23, 33, 44);

    --neutral-color-0: #FFFFFF;
    --neutral-color-100: #F7F9FA;
    --neutral-color-125: #F0F4F7;
    --neutral-color-150: #EBF1F3;
    --neutral-color-175: #E9EDF2;
    --neutral-color-200: #DFE5EB;
    --neutral-color-250: #D1DAE3;
    --neutral-color-300: #BAC4CE;
    --neutral-color-400: #9EAAB6;
    --neutral-color-500: #8C97A5;
    --neutral-color-600: #6C7C8B;
    --neutral-color-700: #566473;
    --neutral-color-800: #394551;
    --neutral-color-900: #17212C;

    --primary-color-100: #DFF1F9;
    --primary-color-125: #B8E4F7;
    --primary-color-200: #56BDEA;
    --primary-color-500: #1EB3F3;
    --primary-color-550: #1BAEED;
    --primary-color-600: #16A6E4;
    --primary-color-600a: #16A6E433;
    --primary-color-700: #1199D3;

    --green-color-100: #EBFBF2;
    --green-color-125: #A7F3CB;
    --green-color-500: #5FC884;
    --green-color-600: #43B36A;

    --purple-color-100: #F5EDFB;
    --purple-color-125: #E7D5F6;
    --purple-color-500: #A955EB;
    --purple-color-600: #8538C1;

    --orange-color-100: #FFEED5;
    --orange-color-125: #F7DAAE;
    --orange-color-500: #FFA217;
    --orange-color-600: #DD890B;

    --red-color-100: #F9DFDF;
    --red-color-125: #F0CCCC;
    --red-color-200: #E67E7E;
    --red-color-500: #EE5B5B;
    --red-color-600: #E14646;

    --selected-color: var(--primary-color-500);
    --border-color: var(--neutral-color-250);
    --divider-color: var(--neutral-color-150);
    --item-hover-color: var(--neutral-color-100);
    --item-selected-color: var(--neutral-color-125);
    --button-hover-color: var(--neutral-color-400);
    --focus-color: var(--primary-color-600);
    --disabled-color: var(--neutral-color-250);
    --danger-action-hover-color: var(--red-color-100);

    --shadow-color-rgb: 23, 33, 44;
    --shadow-color: rgba(var(--shadow-color-rgb), 0.1);

}

#root {
    background-color: var(--neutral-color-100);
}

.enable-events {
    pointer-events: all;
}

.app-container {
    --app-container-inset: .8rem;

    pointer-events: none;
    position: absolute;
    inset: var(--app-container-inset);

    display: grid;
    grid-template-rows: auto 1fr;
    gap: var(--app-container-inset);
}

.da-flex-fill {
    display: flex;

    & > * {
        flex-grow: 1;
    }
}

.da-card {
    --da-card-inset: 1.6rem;
    padding: var(--da-card-inset);
}

.da-card,
.da-overflow {

    background-color: var(--neutral-color-0);
    border-radius: 1.2rem;
    overflow: hidden;

    box-shadow: 0 .2rem 1.6rem hsl(from var(--shadow-color) H S L / .1);
}

.selected-row {
    background-color: var(--selected-color);
}

.loader-container {
    --loading-size: 3.4rem;

    position: absolute;
    inset: 0;
    z-index: 99999;
}

.inline-loader-container {
    --loading-size: 1.8rem;
}

.loader-container,
.inline-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;

    color: black;

    svg {
        width: var(--loading-size);
        height: var(--loading-size);
    }
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 25px;
    font-weight: bold;
}

.da-checkbox {
    cursor: pointer;
    display: flex;
}

.da-checkbox[aria-checked="true"] {
    svg {
        color: var(--primary-color-600)
    }
}

.da-checkbox[aria-disabled="true"] {
    cursor: default;

    svg {
        color: var(--disabled-color);
    }
}

.da-modal {
    position: absolute;
    inset: 0;
    z-index: 999;

    display: flex;
    align-items: center;
    justify-content: center;
}

.da-modal-content-background {
    position: absolute;
    inset: 0;

    background-color: rgba(var(--shadow-color-rgb), 0.4);
}

.da-modal-content {
    --inset-vertical: 8vh;
    --inset-horizontal: 10vw;

    position: absolute;
    inset: var(--inset-vertical) var(--inset-horizontal);
}

.da-modal-entry-input-content {
    display: flex;
    gap: .8rem;

    align-items: center;
}

.da-modal-entry-input {
    button {
        font-size: var(--font-size-100);
        padding: .4rem;
    }

    input,
    textarea,
    span {
        flex-grow: 1;
    }

    textarea {
        min-height: 10.8rem;
    }
}

.da-modal-entry-content {
    inset: unset;

    min-width: 39.2rem;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    overflow: unset;
}

.da-modal-entry-select {
    flex-grow: 1;

    .da-select-container {
        border: 1px solid var(--border-color);
        border-radius: .8rem;
        background-color: transparent;
    }

    .da-select__control {
        background-color: transparent;

        &:hover {
            cursor: pointer;
        }
    }

    .da-select__option {
        text-align: left;
    }
}

.da-modal-entry-date-input {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;

    border: 1px solid var(--border-color);
    border-radius: .8rem;
    padding: .6rem .8rem;

    .da-modal-entry-date-input-content {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-500);
    }

    input {
        width: .1rem;
        display: inline;
        border: unset;
        padding-inline: 0;
        padding-block: .5rem;
        overflow: hidden;
        color: transparent;
        caret-color: var(--neutral-color-900);
    }

    input:focus {
        border: unset;
    }

    svg {
        color: var(--neutral-color-400)
    }
}

.da-modal-entry-date-input > div {
    padding-top: .2rem;
}

.da-modal-entry-date-input:focus-within {
    border: 1px solid var(--focus-color);
}

.da-modal-invite-content {
    width: 48rem;
}

.da-modal-invite-body {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    border-radius: .8rem;
    border: solid 1px var(--border-color);

    padding: 0 .2rem;

    input {
        flex-grow: 1;
        border: none;
        outline: none;
    }

    input:focus-visible {
        border: none;
        outline: none;
    }

    .da-dropdown-button:hover {
        border: none;
        outline: none;
    }
}

.da-modal-invite-body:focus-within {
    border-color: var(--focus-color);
}

.da-modal-invite-body:hover {
    border-color: var(--button-hover-color);
}

.da-modal-delete-content {
    .da-modal-entry-header {
        span {
            color: var(--neutral-color-900);
            font-size: var(--font-size-800);
            font-weight: var(--font-weight-600);
            line-height: var(--font-lineheight-600);
        }
    }
}

.da-modal-entry-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-500);
    }

    & > div {
        display: flex;
        align-items: center;
        gap: .8rem
    }
}

.da-modal-entry-body {

}

.da-modal-delete-body {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    p {
        color: var(--neutral-color-600);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-400);
        line-height: var(--font-lineheight-500);

        span {
            font-weight: var(--font-weight-600);
        }
    }

    div {
        display: flex;
        flex-direction: column;
    }
}

.da-modal-entry-input {
    display: flex;
    flex-direction: column;
    gap: .3rem;

    .da-modal-entry-input-text {
        color: var(--neutral-color-900);
    }
}

.da-modal-entry-input-label {
    color: var(--neutral-color-500);
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-200);
    line-height: var(--font-lineheight-350);
}

.da-modal-entry-footer {
    display: flex;
    justify-content: end;
    gap: 1.2rem;
}

.da-modal-contact-admin-card {
    min-height: 26.4rem;

    .da-modal-entry-body,
    textarea {
        flex-grow: 1;
    }
}

.da-dropdown-modal {
    position: absolute;
    z-index: 999;
    padding-top: 1.2rem;
}

.da-divider,
.da-vertical-divider {
    background-color: var(--neutral-color-200);
}

.da-divider {
    height: 1px;
}

.da-vertical-divider {
    width: 1px;
    height: 100%;
}

input,
textarea {
    border: 1px solid var(--border-color);
    border-radius: .8rem;
    padding: .6rem .8rem;
}

input:focus-visible,
textarea:focus-visible {
    border: 1px solid var(--focus-color);
    outline: none;
}

/*textarea {*/
/*    padding: 1.6rem;*/
/*}*/

@media (prefers-reduced-motion: no-preference) {
    .loader-container > svg,
    .inline-loader-container > svg,
    .da-indicator-button > svg {
        animation: loader-spin infinite 4s linear;
    }
}

@keyframes loader-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.slider-container {
    --slider-width: 4.4rem;
    --slider-height: 2.4rem;
    --slider-button-size: calc(var(--slider-height) * .8);
    --slider-color: var(--neutral-color-200);
    --slider-animation-speed: 0.4s;

    position: relative;
    cursor: pointer;

    width: var(--slider-width);
    height: var(--slider-height);
}

.slider-container[aria-disabled=true] {
    --slider-color: var(--disabled-color);
    cursor: default;
}

.slider {
    position: absolute;
    inset: 0;

    background-color: var(--slider-color);
    border-radius: calc(var(--slider-height) / 2);
}

.slider:before {
    content: "";

    position: absolute;

    height: var(--slider-button-size);
    width: var(--slider-button-size);
    left: calc((var(--slider-height) - var(--slider-button-size)) / 2);
    bottom: calc((var(--slider-height) - var(--slider-button-size)) / 2);

    background-color: var(--neutral-color-0);
    border-radius: 50%;

    -webkit-transition: var(--slider-animation-speed);
    transition: var(--slider-animation-speed);
}

.slider[aria-checked="true"] {
    --slider-color: var(--primary-color-500);
}

.slider[aria-checked="true"]::before {
    -webkit-transform: translateX(var(--slider-button-size));
    -ms-transform: translateX(var(--slider-button-size));
    transform: translateX(var(--slider-button-size));
}

.slider[aria-disabled="true"] {
    --slider-color: var(--disabled-color);
    cursor: default;
}

.slider-container[aria-disabled=false]:hover {
    --slider-color: var(--neutral-color-250);
}

.slider-container[aria-disabled=false] .slider[aria-checked="true"]:hover {
    --slider-color: var(--primary-color-600);
}

.name-icon {
    --icon-size: 3.2rem;
    --icon-color: var(--primary-color-500);
    --icon-hover: var(--primary-color-600);

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: var(--icon-size);
    height: var(--icon-size);

    color: var(--neutral-color-0);
    background-color: var(--icon-color);
    border-radius: 50%;
}

.name-icon > span {
    color: var(--neutral-color-0);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.da-search {
    --outline-color: transparent;

    cursor: pointer;
    display: flex;
    align-items: center;
    padding: .6rem .8rem;
    position: relative;
    min-height: 4.4rem;

    flex-grow: 1;

    color: var(--neutral-color-500);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);

    &:focus-within {
        --outline-color: var(--focus-color);
    }

    &:before {
        border-radius: .8rem;
        outline: solid 1px var(--outline-color);

        content: "";
        position: absolute;
        inset-block: 1px;
        inset-inline: 2px;
    }
}

.da-search-input {
    background-color: transparent;
    border: none;

    flex: 1;
}

.da-search-input:focus-visible {
    border: none;
    outline: none;
}

.da-search.dark-search {
    border-radius: .4rem;
    background: var(--neutral-color-125);
}

.da-select-container {
    color: var(--neutral-color-900) !important;
    font-size: var(--font-size-300) !important;
    font-weight: var(--font-weight-500) !important;
    line-height: var(--font-lineheight-500) !important;

    flex-grow: 1;
}

.da-select-container:focus {
    outline-color: var(--focus-color);
}

.da-select__control {
    border: none !important;
}

.da-select__control--is-focused {
    box-shadow: none !important;
    border: solid 1px var(--focus-color) !important;
}

.da-select__indicator-separator {
    display: none;
}

.da-select__menu {
    right: 0;
    text-align: right;
    min-width: 25rem;
}

.da-select__menu-list {
    padding: 0 .8rem;
}

.da-select__value-container,
.da-select__option {
    color: var(--neutral-color-900) !important;
    font-size: var(--font-size-300) !important;
    font-weight: var(--font-weight-500) !important;
    line-height: var(--font-lineheight-500) !important;
}

.da-select__option {
    --da-select-border-radius: .4rem;

    border-radius: 0;
}

.da-select__option:only-child {
    border-radius: var(--da-select-border-radius) var(--da-select-border-radius) var(--da-select-border-radius) var(--da-select-border-radius);
}

.da-select__option:not(:only-child).da-select__option:first-child {
    border-radius: var(--da-select-border-radius) var(--da-select-border-radius) 0 0;
}

.da-select__option:not(:only-child).da-select__option:last-child {
    border-radius: 0 0 var(--da-select-border-radius) var(--da-select-border-radius);
}

.da-select__option--is-focused {
    background-color: var(--item-hover-color) !important;
}

.da-select__option--is-selected {
    background-color: var(--item-selected-color) !important;
}

.da-colored-select {
    --da-colored-select-inline-padding: 1.2rem;

    position: relative;
    display: flex;
    flex-direction: column;
}

.da-colored-select > div:first-child {
    display: flex;
}

.da-colored-select-value-container {
    cursor: pointer;

    display: flex;
    flex-shrink: 1;

    border-radius: .8rem;
}

.da-colored-select-values {
    display: flex;
    gap: .8rem;
    align-items: center;

    padding-inline: var(--da-colored-select-inline-padding);
}

.da-colored-select-values-icon {
    border-radius: 50%;
    height: .8rem;
    aspect-ratio: 1 / 1;
}

.da-colored-select-values-text {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.da-colored-select-value-divider {
    display: flex;
    align-items: center;

    svg {
        padding-inline: .4rem;
        width: 2rem;
        aspect-ratio: 1 / 1;
    }
}

.da-colored-select-options-container {
    position: relative;
    min-width: 20rem;
}

.da-colored-select-options {
    position: absolute;
    top: .4rem;
    padding: unset;
    border-radius: .6rem;
    z-index: 1;
    min-width: 20rem;
}

.da-colored-select-options-item {
    padding-block: .2rem;
    padding-inline: var(--da-colored-select-inline-padding);
}

.da-colored-select-options-item:hover {
    background-color: var(--item-hover-color);
}

.da-colored-select-options-item:not(:first-child) {
    border-top: solid 1px var(--divider-color);
}

.da-tab {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    flex-grow: 1;
}

.da-tab-header {
    display: flex;
    gap: 2rem;

    border-bottom: 1px solid var(--neutral-color-150);
}

.da-tab-body {
    flex-grow: 1;
}

.da-tab-header-item {
    cursor: pointer;

    position: relative;

    padding: .6rem 1.2rem;

    color: var(--neutral-color-600);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-500);
}

.da-tab-header-item-selected {
    position: relative;

    color: var(--selected-color);
}

.da-tab-header-item-selected::before {
    content: "";
    position: absolute;

    --bar-height: .3rem;

    bottom: calc((var(--bar-height) + var(--tab-header-item-padding, 0rem)) * -1);
    left: 0;
    right: 0;
    height: var(--bar-height);
    background-color: var(--selected-color);
}

.settings-banner {
    position: relative;

    height: 4rem;
}

.settings-banner > div {
    position: absolute;

    inset-inline: calc(-1 * var(--modal-horizontal-padding, 0));
    inset-block: 0;

    display: flex;
    gap: .6rem;

    padding-inline: 3.2rem;
    align-items: center;

    background-color: hsl(from var(--primary-color-600) H S L / .1);

    color: var(--primary-color-600);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.settings-banner > div > span {
    cursor: pointer;
    border-bottom: solid 1px currentColor;
}

.da-empty-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2.4rem .8rem;

    background-color: var(--neutral-color-100);
    border-radius: 0.8rem;

    color: var(--neutral-color-600);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-400);
    line-height: var(--font-lineheight-100);
}

.da-empty-view > span {
    text-align: center;
}

.scroll-view {
    height: 100%;
    position: relative;
    overflow-y: scroll;
}

.scroll-view-container {
    position: absolute;
    inset: 0;
}

.paginated-scroll-view-loading-item {
    display: flex;
    justify-content: center;
    padding-inline: 1.2rem;
    padding-block: 2rem;

    span {
        color: var(--neutral-color-600);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-100);
    }
}

.scrollable {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.da-segment-select {
    display: flex;
    align-items: center;
    gap: 2rem;

    padding: .4rem;
    background-color: var(--neutral-color-125);
    border-radius: 1rem;
}

.da-segment-select.primary {
    --segment-outline-color: var(--neutral-color-200);

    gap: .4rem;
    background-color: transparent;

    & > div {
        min-width: 4.4rem;
        min-height: 4rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
        border-radius: 0.8rem;
        outline: solid 1px var(--segment-outline-color);
    }

    .da-segment-select-option-selected {
        --segment-outline-color: var(--selected-color);
        color: var(--neutral-color-0);
        background-color: var(--selected-color);
    }

    .da-segment-select-option-not-selected {
        color: var(--neutral-color-900);
    }
}

.da-segment-select-option-selected {
    background-color: var(--neutral-color-0);
    border-radius: .8rem;

    color: var(--neutral-color-900);
    font-weight: var(--font-weight-600);
}

.da-segment-select-option-not-selected {
    color: var(--neutral-color-600);
    font-weight: var(--font-weight-500);
}

.da-segment-select-option-selected,
.da-segment-select-option-not-selected {
    cursor: pointer;

    display: flex;
    gap: 1rem;

    padding-block: .6rem;
    padding-inline: 1.8rem;

    font-size: var(--font-size-200);
    line-height: var(--font-lineheight-100);
}

.da-overflow {
    position: absolute;
    display: flex;
    flex-direction: column;

    right: 0;
    z-index: 800;
}

.da-overflow-item {
    cursor: pointer;

    padding: .4rem;
    min-width: 20rem;
}

.da-overflow-item-layout {
    display: flex;
    gap: .8rem;

    padding-block: .8rem;
    padding-inline: 1.6rem;
    border-radius: .8rem;

    color: var(--neutral-color-800);

    svg {
        width: 2rem;
        height: 2rem;
        color: inherit;
    }

    span {
        color: inherit;
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-300);
        line-height: var(--font-lineheight-500);
    }

    &:hover {
        background-color: var(--item-hover-color);
    }
}

.da-overflow-item-layout.danger-text {
    color: var(--red-color-500);

    &:hover {
        background-color: var(--danger-action-hover-color);
    }
}

.da-pill-group {
    display: flex;
    gap: 1.2rem;
}

.da-pill {
    --pill-outline-color: var(--neutral-color-900);
    --pill-background-color: var(--neutral-color-0);
    --pill-text-color: var(--neutral-color-900);

    cursor: pointer;

    padding-block: .8rem;
    padding-inline: 1.2rem;

    border-radius: 0.8rem;
    outline: solid 1px var(--pill-outline-color);
    background-color: var(--pill-background-color);

    color: var(--pill-text-color);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-100);

    &:hover {
        --pill-background-color: var(--neutral-color-100);
    }

    &[aria-selected="true"] {
        --pill-outline-color: var(--primary-color-700);
        --pill-background-color: var(--primary-color-600);
        --pill-text-color: var(--neutral-color-0);

        &:hover {
            --pill-outline-color: var(--primary-color-600);
            --pill-background-color: var(--primary-color-500);
        }
    }
}

.column-flex {
    display: flex;
    flex-direction: column;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

.two-column-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.gap-100 {
    gap: .4rem;
}

.gap-200 {
    gap: .8rem;
}

.gap-300 {
    gap: 1.2rem;
}

.gap-400 {
    gap: 1.6rem;
}

.gap-500 {
    gap: 2rem;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}