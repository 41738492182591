.login-redirect {
    width: 100vw;
    height: 100vh;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-redirect-background {
    pointer-events: none;

    position: absolute;
    inset: 0;

    background: linear-gradient(180deg, rgba(33, 97, 165, 0.1) 0%, rgba(33, 97, 165, 0.03) 100%);

    display: flex;
    align-items: end;
}

.login-redirect-background > div {
    display: flex;
    overflow: hidden;
}

.login-redirect-container {
    pointer-events: all;

    display: flex;
    flex-direction: column;
    gap: 3.2rem;

    align-items: center;

    .da-card {
        min-width: 42rem;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        align-items: center;
        padding-block: 4.8rem;
        padding-inline: 5.6rem;

        h1 {
            font-size: var(--font-size-975);
            font-weight: var(--font-weight-600);
            line-height: var(--font-lineheight-700);
        }

        div {
            display: flex;
            flex-direction: column;
            gap: .8rem;

            align-items: center;
        }

        span {
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-400);
            line-height: var(--font-lineheight-400);
        }

        a {
            cursor: pointer;

            padding-block: .4rem;
            border-radius: .8rem;

            background-color: var(--primary-color-500);
            width: 100%;

            color: var(--neutral-color-0);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-500);
            text-align: center;
            text-decoration: none;
        }

        a:hover {
            background-color: var(--primary-color-600);
        }
    }
}