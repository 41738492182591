.login-redirect {
    position: absolute;
    inset: 0;
}

.da-navbar-school {
    position: relative;

    display: flex;
    gap: 1.2rem;
    align-items: center;

    padding-inline: 1.2rem .4rem;
    padding-block: .8rem;
    width: 30rem;

    border-radius: .8rem;
    border: 1px solid var(--border-color);
    background-color: var(--neutral-color-125);

    .da-select-container {
        position: unset;
    }

    .da-select__control {
        background-color: transparent;
        min-height: unset;
        gap: 1.2rem;
    }

    .da-select__control--is-focused {
        border: none !important;
    }

    .da-select__indicator {
        padding-block: 0;
    }

    .da-select__menu {
        min-width: unset;
        left: 0;
        right: 0
    }

    .da-select__menu-list {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        padding-inline: 1.2rem;
        padding-block: .8rem;
    }
}

.da-navbar-school-option {
    display: flex;
    gap: .6rem;
    align-items: center;

    padding-block: .4rem;

    cursor: pointer;

    span {
        text-align: start;
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-500);
    }

    svg {
        color: var(--neutral-color-600)
    }
}

.da-nav-dropdown-item-layout {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .8rem;

    .badge {
        position: unset;
    }
}

.badge {
    --badge-size: 1.6rem;

    background-color: var(--red-color-500);
    color: var(--neutral-color-0);
    border-radius: 50%;

    position: absolute;
    top: calc(var(--badge-size) * -1 / 4);
    right: calc(var(--badge-size) * -1 / 4);
    z-index: 999;

    width: var(--badge-size);
    height: var(--badge-size);

    display: flex;
    align-items: center;
    justify-content: center;
}

.school-icon {
    --school-icon-size: 2.4rem;
    --school-icon-color: var(--neutral-color-900);

    color: var(--school-icon-color);

    width: var(--school-icon-size);
    height: var(--school-icon-size);
    border-radius: .4rem;

    svg {
        color: inherit;
        width: var(--school-icon-size);
        height: var(--school-icon-size);
    }
}

.school-icon-green {
    --school-icon-color: var(--green-color-500);
}

.school-icon-red {
    --school-icon-color: var(--red-color-500);
}

.school-icon-orange {
    --school-icon-color: var(--orange-color-500);
}

.school-icon-purple {
    --school-icon-color: var(--purple-color-500);
}

.school-icon-blue {
    --school-icon-color: var(--primary-color-500);
}
