.wireless-config-tab-layout {
    .da-tab-header {
        border: unset;
    }
}

.wireless-config-overlay-pane-toggle {
    pointer-events: all;
    display: flex;
    gap: 1rem;

    position: absolute;
    top: 0;
    right: 0;
}

.wireless-config-overlay-left-pane-ap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wireless-config-overlay-left-pane-ap-content {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    height: 100%;
    /* add margin at the bottom for mapbox logo */
    margin-bottom: 2rem;
}

.wireless-config-overlay-left-pane-ap-content-header {
    --wireless-config-overlay-left-pane-ap-content-header-padding: 1.6rem;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding: var(--wireless-config-overlay-left-pane-ap-content-header-padding);
    position: relative;

    .wireless-config-overlay-left-pane-ap-content-header-buttons {
        display: grid;
        gap: .8rem;
        grid-template-columns: auto 1fr;

        align-items: center;

        & > div {
            display: flex;
            align-items: center;
        }
    }

    .wireless-config-overlay-left-pane-ap-content-options {
        display: flex;
        gap: 1.2rem;
        align-items: center;
    }

    .da-search > input {
        min-height: 3.2rem;
    }

    &:before {
        content: "";
        position: absolute;

        bottom: 0;
        left: calc(var(--wireless-config-overlay-left-pane-ap-content-header-padding) * -1);
        right: calc(var(--wireless-config-overlay-left-pane-ap-content-header-padding) * -1);
        height: .1rem;

        background-color: var(--border-color);
    }
}

.wireless-config-overlay-left-pane-ap-content > div:last-child {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.wireless-config-overlay-left-pane-ap-row {
    cursor: pointer;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-column-gap: .8rem;
    align-items: center;

    padding-block: .8rem;
    padding-inline: .6rem;

    border-radius: .8rem;
}

.wireless-config-overlay-left-pane-ap-row:hover {
    background-color: var(--item-hover-color);
}

.wireless-config-overlay-left-pane-ap-row[aria-selected=true] {
    background-color: var(--item-selected-color);
}

.wireless-config-overlay-left-pane-ap-row-indicator-success {
    --wireless-config-overlay-left-pane-ap-row-indicator-indicator-color: var(--green-color-500)
}

.wireless-config-overlay-left-pane-ap-row-indicator-danger {
    --wireless-config-overlay-left-pane-ap-row-indicator-indicator-color: var(--red-color-500)
}

.wireless-config-overlay-left-pane-ap-row-indicator {
    height: .8rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;

    background-color: var(--wireless-config-overlay-left-pane-ap-row-indicator-indicator-color);
}

.wireless-config-overlay-left-pane-ap-row-primary {
    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-400);
}

.wireless-config-overlay-left-pane-ap-row-secondary {
    color: var(--neutral-color-400);
    font-size: var(--font-size-100);
    font-weight: var(--font-weight-400);
    line-height: var(--font-lineheight-100);
}

.wireless-config-overlay-right-pane-ap {
    display: flex;
    flex-direction: column;
    gap: .6rem;
}

.wireless-config-overlay-right-pane-ap-header {
    padding-block: 1.2rem;
}

.wireless-config-overlay-right-pane-ap-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.wireless-config-overlay-right-pane-ap-content-sliders {
    display: flex;
    gap: 1.6rem;
    justify-content: space-between;
}

.wireless-config-overlay-right-pane-ap-content-title {
    color: var(--neutral-color-900);
    font-size: var(--font-size-950);
    font-weight: var(--font-weight-600);
    line-height: var(--font-lineheight-800);
}

.wireless-config-overlay-right-pane-ap-content-patterns {
    display: flex !important;
    flex-direction: column !important;
    gap: .4rem !important;
}

.wireless-config-overlay-right-pane-ap-content-pattern-item {
    border-radius: .8rem;
    border: solid 1px var(--border-color);

    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    padding-block: 1.6rem !important;
    padding-inline: .8rem !important;
    align-items: start !important;

    span {
        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-100);
    }
}

.wireless-config-detail {
    height: 100%;

    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: .6rem;

    &.two-column {
        grid-template-rows: auto 1fr;
    }

    &.three-column {
        grid-template-rows: auto 1fr auto;
    }

    .wireless-config-detail-text-buttons {
        display: flex;
        gap: .4rem;
        align-items: center;

        button {
            max-height: 2rem;
            max-width: 2rem;
        }
    }

    .wireless-config-detail-title {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;

        .wireless-config-detail-title-content {
            display: flex;
            gap: 1rem;
            align-items: center;

            input {
                --border-color: transparent;

                flex-grow: 1;

                color: var(--neutral-color-900);
                font-size: var(--font-size-950);
                font-weight: var(--font-weight-600);
                line-height: var(--font-lineheight-800);
            }
        }

        & .wireless-config-detail-title-buttons:empty {
            display: none;
        }
    }

    .wireless-config-detail-description-view {
        --description-line-height: var(--font-lineheight-400);

        display: flex;
        gap: 1rem;
        flex-direction: column;

        textarea {
            flex-grow: 1;
            color: var(--neutral-color-900);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-400);
            line-height: var(--description-line-height);

            resize: vertical;
            min-height: calc(var(--description-line-height) * 3);
        }
    }

    .scrollable {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }
}

.wireless-config-detail-header {
    display: flex;
    flex-direction: column;
    gap: .4rem;

}

.wireless-config-detail-titled-attributes {
    .wireless-config-detail-titled-attributes-title {
        padding-block: .4rem;

        color: var(--neutral-color-900);
        font-size: var(--font-size-300);
        font-weight: var(--font-weight-600);
        line-height: var(--font-lineheight-500);
    }

    .wireless-config-detail-titled-attributes-title:empty {
        display: none;
    }

    .wireless-config-detail-titled-attributes-content {
        &.single {
            display: flex;
            flex-direction: column;
            gap: .4rem;
        }

        &.split {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            .wireless-config-detail-titled-attributes-item-title {
                height: unset;
                padding-block: .4rem;
            }
        }

        .da-modal-entry-input-label {
            color: var(--neutral-color-600);
            font-size: var(--font-size-75);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-100);
            letter-spacing: .035rem;

            text-transform: uppercase;
        }
    }


    .wireless-config-detail-titled-attributes-item {
        --content-min-height: 4.4rem;

        min-height: var(--content-min-height);
        padding-inline: 1.2rem .1rem;

        &.horizontal {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;

            /*&:hover input {*/
            /*    --border-color: var(--button-hover-color);*/
            /*}*/
        }

        &.vertical {
            --content-min-height: 4rem;

            display: flex;
            flex-direction: column;
        }

        & > .wireless-config-detail-titled-attributes-item-title {
            display: flex;
            height: var(--content-min-height);
            align-items: center;

            .da-modal-entry-input-label {
                flex-grow: 1;
            }
        }

        & > .wireless-config-detail-titled-attributes-item-content {
            min-height: var(--content-min-height);

            & input {
                --border-color: transparent;
            }

            &:has(input),
            &:has(.da-select-container),
            &:has(.slider-container) {
                display: flex;
                align-items: center;
            }

            &:has(input),
            &:has(.da-select-container) {
                & > * {
                    flex-grow: 1;
                }
            }

            .slider-container {
                margin-left: 1.2rem;
            }
        }
    }

    .wireless-config-detail-titled-attributes-item-content,
    .wireless-config-detail-attributes-multi-content {
        input,
        span {
            color: var(--neutral-color-900);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-500);

        }
    }

    .wireless-config-detail-attributes-multi-content {
        --border-width: 1px;

        display: flex;
        flex-direction: column;
        gap: .4rem;
        padding: .8rem 1.2rem;
        margin-right: var(--border-width);
        border-radius: .4rem;
        height: 100%;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }

        &[aria-selected="true"] {
            outline: solid 1px var(--selected-color);
        }
    }

    .wireless-config-detail-attributes-override-item {
        --border-color: transparent;

        display: flex;
        gap: .8rem;
        align-items: center;

        height: 100%;

        & > *:first-child {
            max-width: 7rem;
        }

        & > * {
            color: var(--neutral-color-900);
            font-size: var(--font-size-300);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-500);
        }
    }

    &:has(> .wireless-config-detail-titled-attributes-content:empty) {
        display: none;
    }
}

.wireless-config-detail-attributes-operation-hours-modal {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    max-height: calc(100vh * .75);

    .wireless-config-detail-attributes-operation-hours-modal-item {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;

        padding-block: 2rem 1.2rem;
        padding-inline: 1.6rem;
        border-radius: .8rem;

        background-color: var(--neutral-color-100);
    }
}

.wireless-config-detail-attributes-floor-modal {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    max-height: 30rem;

    & .scroll-view {
        min-height: 20rem;
    }

    & .scrollable {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        align-items: start;
    }

    .wireless-config-detail-attributes-floor-modal-existing-floors {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        width: 100%;

        & > div {
            display: grid;
            grid-template-columns: 1fr auto;
            gap: 1.6rem;
            align-items: center;
        }
    }

    .wireless-config-detail-attributes-floor-modal-controls {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        button {
            justify-content: start;
        }
    }
}

.wireless-config-detail-buttons {
    display: flex;
    gap: 1.2rem;

    & > button {
        height: 3.6rem;
    }

    & > button:first-child {
        margin-left: auto;
        width: 9.1rem;
    }

    & > button:last-child {
        width: 16.3rem;
    }
}

.wireless-config-overlay-filter-modal {
    width: 60rem;

    .wireless-config-overlay-filter-modal-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;

    }
}

.wireless-config-status-selector {
    display: flex;

    border-radius: 1rem;
    padding: .4rem;
    background-color: var(--neutral-color-125);

    span {
        flex-grow: 1;

        padding-block: .6rem;
        padding-inline: 1.8rem;
        border-radius: .8rem;
        text-align: center;

        color: var(--neutral-color-900);
        font-size: var(--font-size-200);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-100);

        &[aria-selected="true"] {
            pointer-events: none;
            background-color: var(--neutral-color-0);
        }

        &[aria-selected="false"] {
            cursor: pointer;
            color: var(--neutral-color-600);

            &:hover {
                background-color: var(--neutral-color-200);
            }
        }
    }
}

.building-operating-hours {
    cursor: pointer;
    padding-block-start: 1rem;
    padding-inline-start: 1rem;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
    letter-spacing: 0;

    .da-empty-view {
        border: solid 1px var(--neutral-color-200);
        padding-block: .6rem;
    }
}