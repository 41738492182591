.attribute-input {
    --button-size: 2.4rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.8rem;

    align-items: center;

    & button {
        display: flex;

        justify-content: center;
        align-items: center;

        height: var(--button-size, 2.4rem);
        width: var(--button-size, 2.4rem);
        border-radius: 50%;
    }

    & svg {
        overflow: unset;
        overflow-clip-margin: unset;
    }
}

.attribute-input-text {
    --border-color: transparent;

    padding-block: .6rem;
    padding-inline: .8rem;

    outline: solid 1px var(--border-color);
    border-radius: .8rem;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);
}

.attribute-input-text:hover {
    --border-color: var(--neutral-color-400);
}

.attribute-input-text.focused,
.attribute-input-text:focus-visible {
    --border-color: var(--focus-color);
}