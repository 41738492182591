.da-navbar-layout {
    .da-select__menu {
        z-index: 9999;
    }
}

#da-navbar-tabs:empty {
    display: none;
}

#da-navbar-tabs:not(:empty) {
    --bar-padding: 1rem;
    --tab-header-item-padding: calc(var(--bar-padding) - var(--bar-height, .3rem));

    display: block;
    padding-block: var(--bar-padding);
    padding-inline: 2.2rem;
    border-top: solid .1rem var(--border-color);
}