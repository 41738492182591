.da-table-view {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: .8rem;
}

.da-table-view-header,
.da-table-view-row {
    display: grid;
    gap: .8rem;
}

.da-table-view-header {
    span {
        color: var(--font-color-400);
        font-size: var(--font-size-75);
        font-weight: var(--font-weight-500);
        line-height: var(--font-lineheight-100);
        letter-spacing: .05rem;
    }
}

.da-table-view-header-overflow {
    .da-icon-button {
        height: 0;
        color: transparent;
        pointer-events: none;
    }
}

.da-table-view-content {
    overflow: scroll;
    position: relative;
}

.da-table-view-content-scrollable {
    position: absolute;
    inset-block-start: 0;
    inset-inline: 0;

    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}

.da-table-view-row {
    padding-block: 1rem;
    padding-inline: .8rem;
    border-radius: .8rem;
    background-color: var(--neutral-color-100);

    align-items: center;
}

.da-table-view-row-action-buttons {
    display: flex;
    gap: 2rem;
    justify-content: end;
}

.da-table-view-row-cell {
    font-size: var(--font-size-300);
}