button {
    cursor: pointer;

    display: flex;
    gap: .8rem;
    align-items: center;
    justify-content: center;

    font-weight: var(--font-weight-500);
    font-size: var(--font-size-300);
    line-height: var(--font-lineheight-500);
}

.da-button {
    border: none;
    border-radius: .8rem;
    padding-inline: 1.2rem;
    padding-block: .6rem;
}

button[disabled] {
    cursor: default;
}

.da-button-primary {
    background-color: var(--primary-color-500);
    color: var(--neutral-color-0);
}

.da-button-primary.da-button-no-outline {
    padding: .4rem 0;

    background-color: transparent;
    color: var(--primary-color-500);
}

.da-button-primary:hover {
    background-color: var(--primary-color-600);
}

.da-button-primary.da-button-no-outline:hover {
    background-color: transparent;
    color: var(--primary-color-600);
}

.da-button-primary[disabled] {
    color: var(--neutral-color-0);
    background-color: var(--neutral-color-250);
}

.da-button-primary.da-button-no-outline[disabled] {
    background-color: transparent;
    color: var(--neutral-color-500);
}

.da-button-secondary {
    background-color: var(--neutral-color-150);
    color: var(--neutral-color-800);
}

.da-button-secondary:hover {
    background-color: var(--neutral-color-200);
}

.da-button-secondary[disabled] {
    color: var(--neutral-color-500);
    background-color: var(--neutral-color-125);
}

.da-button-danger {
    background-color: var(--red-color-500);
    color: var(--neutral-color-0);
}

.da-button-danger:hover {
    background-color: var(--red-color-600);
}

.da-button-danger[disabled] {
    background-color: var(--red-color-100);
}

.da-icon-button {
    --icon-border-radius: .4rem;
    --icon-button-size: 3.2rem;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    border: none;
    background: none;

    aspect-ratio: 1 / 1;
    height: var(--icon-size, 3.2rem);
}

.da-icon-button:disabled {
    --button-hover-color: transparent;
}

.da-icon-button::before {
    content: "";

    position: absolute;
    inset: 0;

    background-color: var(--button-hover-color);
    border-radius: var(--icon-border-radius);
    opacity: 0;
    transition: opacity 0.25s;
}

.da-icon-button:hover::before {
    opacity: .2
}

.da-icon-button.circle {
    --icon-border-radius: 50%;
}

.da-icon-button-primary {
    color: var(--primary-color-600);
    --button-hover-color: var(--primary-color-200);
}

.da-icon-button-secondary {
    color: var(--neutral-color-600);
    --button-hover-color: var(--neutral-color-400);
}

.da-icon-button-secondary[disabled] {
    color: var(--neutral-color-400);
}

.da-icon-button-danger {
    color: var(--red-color-500);
    --button-hover-color: var(--red-color-200);
}

.da-icon-button-clear {
    --button-hover-color: transparent;

    &:hover {
        --button-hover-color: transparent;
    }
}

.da-indicator-button {
    display: flex;
    gap: .4rem;
}

.da-dropdown-button {
    /* give it a border color so that the ui doesn't jump around during hover states  */
    --da-dropdown-button-border-color: transparent;

    cursor: pointer;

    display: inline-block;
    padding: .8rem 1.2rem;

    color: var(--neutral-color-900);
    font-size: var(--font-size-300);
    font-weight: var(--font-weight-500);
    line-height: var(--font-lineheight-500);

    border-radius: .8rem;
    border: solid 1px var(--da-dropdown-button-border-color);

    svg {
        color: var(--neutral-color-500);
    }
}

.da-dropdown-button:hover {
    --da-dropdown-button-border-color: var(--neutral-color-250);
}

.da-dropdown-button-container {
    display: flex;
    align-items: center;
}

.da-button-copy {
    --button-padding: .8rem;
    padding: .8rem;

    svg {
        width: 2rem;
        height: 2rem;
    }
}