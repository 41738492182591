.spark-view {
    --content-width: 80rem;

    pointer-events: all;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    .spark-view-content {
        max-width: var(--content-width);

        display: flex;
        flex-direction: column;
        gap: 2rem;

        & > span {
            color: var(--neutral-color-900);
            font-size: var(--font-size-400);
            font-weight: var(--font-weight-500);
            line-height: var(--font-lineheight-500);
        }
    }

    .spark-view-buttons {
        max-width: var(--content-width);
        display: flex;
        justify-content: end;
    }
}